import * as React from 'react';

interface Props {
  className?: string;
}

export default function VideoCloseIcon({ className }: Props): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <circle fillOpacity=".7" fill="#000" cx="12" cy="12" r="12" />
        <path
          d="M17.657 6.343a1 1 0 0 1 0 1.414L13.415 12l4.242 4.244a1 1 0 0 1-1.414 1.414L12 13.414l-4.244 4.243a1 1 0 0 1-1.414-1.414l4.243-4.244-4.243-4.242a1 1 0 0 1 1.414-1.414l4.244 4.242 4.242-4.242a1 1 0 0 1 1.414 0z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}
