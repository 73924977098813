import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OutIcon } from '../../components/images/svg';
import { StoreState } from '../../modules';
import { Config } from '../../utils';
import { createParticipantInfoList, ParticipantInfo } from '../conferences';
import { actions as conferenceAction } from '../../modules/conference'

interface Props {
  participants: ParticipantInfo[]
}

export default function ParticipantsModalContent ({ participants }: Props) {
  const { conference, room } = useSelector((state: StoreState) => ({conference: state.conference.conference, room: state.conference.room }))
  const dispatch = useDispatch();

  const banMember = useCallback((memberId) => async () => {
    try {
      await conference.banMember(memberId);
      const participants = await conference.getParticipants()
      dispatch(conferenceAction.setParticipants(createParticipantInfoList(participants)))
    } catch(e) {
      console.error(e);
    }
  }, [])

  return (
    <ul className="participants-modal-list">
      {participants.map((participant, i) => (
        <li key={i} className="participants-modal-item">
          <div className="member-group flex-center">
            <img src={Config.DEFAULT_AVATAR} alt="participant_avatar_image" width="38" height="38" />
            <span>{participant.name}</span>
          </div>
          {(room?.isHost && !participant.isMe) && <button className="ban-group flex-center" onClick={banMember(participant.id)}>
            <OutIcon />
            <span>내보내기</span>
          </button>}
        </li>
      ))}
    </ul>
  )
}