import * as React from 'react';

interface Props {
  className?: string;
}

export default function Mute({ className }: Props): JSX.Element {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h26v26H0z" />
        <path
          d="M16.5 22.6a.5.5 0 0 1 .5.5v.4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-.4a.5.5 0 0 1 .5-.5h7zM21 10v2.1c0 4.016-2.929 7.315-6.666 7.669V22.6h-2.667l-.001-2.831a7.017 7.017 0 0 1-2.422-.684L10.3 18.03c.55.208 1.138.334 1.75.364l.242.006h1.418l.18-.003c3.116-.12 5.625-2.735 5.77-6l.006-.296-.001-2.1H21zm-3 .327V12a5 5 0 0 1-6.455 4.785L18 10.327zM6.333 10v2.1c0 1.223.332 2.365.907 3.332l-.981.983A7.936 7.936 0 0 1 5 12.1V10h1.333zM13 2a5.001 5.001 0 0 1 4.61 3.062l-9.113 9.113A4.98 4.98 0 0 1 8 12V7a5 5 0 0 1 5-5z"
          fill="#FFF"
        />
        <path fill="#FF591F" d="M24.667 1.54 2.04 24.167l-.707-.707L23.96.833z" />
      </g>
    </svg>
  );
}
