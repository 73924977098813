import * as React from 'react';

interface Props {
  className?: string;
}

export default function VideoOn({ className }: Props): JSX.Element {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a" d="M0 0h25.071v16.714H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h26v26H0z" />
        <g transform="matrix(1 0 0 -1 .929 21.357)">
          <mask id="VideoOn" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M24.196 1.93a1.707 1.707 0 0 0-1.73.035l-4.813 2.959V1.447c0-.8-.66-1.447-1.472-1.447H1.47A1.46 1.46 0 0 0 0 1.447v13.82c0 .8.659 1.447 1.472 1.447H16.18a1.46 1.46 0 0 0 1.472-1.447v-3.476l4.813 2.958c.528.325 1.19.338 1.73.035.54-.304.875-.877.875-1.499V3.43c0-.622-.334-1.195-.875-1.498"
            fill="#FFF"
            mask="url(#VideoOn)"
          />
        </g>
      </g>
    </svg>
  );
}
