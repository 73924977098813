import * as React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import { bindActionCreators } from 'redux';
import { StoreState } from '../../../../modules';
import { actions as conferenceActions } from '../../../../modules/conference';
import {
  ConferenceRoomInfo,
  createParticipantInfoList,
  Participant,
  ParticipantInfo,
} from '../../../../conference/conferences';
import ConferenceNavList from '../../../../conference/viewer/ConferenceNavList';
import { Config, Messages } from '../../../../utils';
import { isClient, isWebRTCSupported } from '../../../../utils/browser';
import Toast from '../../../../components/Toast';
import Title from '../../../../components/title/Title';
import WaitingConference from '../../../../conference/viewer/WaitingConference';
import LocalRoomVideo from '../../../../conference/viewer/LocalRoomVideo';
import RemoteRoomVideo from '../../../../conference/viewer/RemoteRoomVideo';
import Modal from '../../../../components/modal/Modal';
import EnterConferenceForm from '../../../../conference/EnterConferenceForm';

enum ModalStateCode {
  End = 1,
  Ban = 2,
  Enter = 3,
}

interface Props {
  location: any;
  actions: typeof conferenceActions;
  streamState: string;
  room: ConferenceRoomInfo;
  myStream: any;
  participants: ParticipantInfo[];
}

interface State {
  roomId: string;
  feeds: any[];
  visibleModal: ModalStateCode;
}

class ConferenceViewerContainer extends React.Component<Props, State> {
  state: State = {
    roomId: '',
    feeds: [],
    visibleModal: 0,
  };

  sdk: any;
  conference: any;

  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if (this.state.visibleModal === modalCode) {
      this.setState({ visibleModal: 0 });
    } else {
      this.setState({ visibleModal: modalCode });
    }
  };

  toggleEnd = this.toggleModalGenerator(ModalStateCode.End);
  toggleBan = this.toggleModalGenerator(ModalStateCode.Ban);
  toggleEnter = this.toggleModalGenerator(ModalStateCode.Enter);

  initFlipFlop = async () => {
    const { FlipFlop } = await import('flipflop-sdk-javascript/dist/flipflop');

    const appKey = Config.DEMO_APP_KEY;
    const appSecret = Config.DEMO_APP_SECRET;
    const username = localStorage.getItem(Config.DEMO_USERNAME_KEY);

    if (!username) {
      Toast.warn('channal and username are reqired');
      navigate('/demo');
      return;
    }

    const profilePhoto = '';

    console.log(`appKey: ${appKey}, appSecret: ${appSecret}`);
    console.log(`userId: ${username}, username: ${username}, profilePhoto - ${profilePhoto}`);

    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(username, username, profilePhoto);

    this.conference = this.sdk.getConference();
    this.props.actions.setConference(this.conference);
  };

  initConference = () => {
    const { roomId } = this.state;
    const pin = isClient && new URLSearchParams(window.location.search).get('pin');
    try {
      const decodingPin = atob(pin);

      const request = {
        roomId,
        pin: decodingPin,
        delegate: this,
        options: {
          useVideo:
            isClient &&
            typeof JSON.parse(localStorage.getItem(Config.DEMO_IS_CAM_KEY)) === 'boolean'
              ? JSON.parse(localStorage.getItem(Config.DEMO_IS_CAM_KEY))
              : false,
          useAudio: true,
        },
      };

      this.conference?.prepare(request).then(async (result) => {
        if (result) {
          const videoEl = document.getElementById('myvideo');
          const room = await this.conference?.start(videoEl);
          console.log('room data', room);
          this.setRoom(room);
        } else {
          this.toggleEnter();
        }
      });
    } catch {
      this.toggleEnter();
    }
  };

  setRoom = (room: any) => {
    this.props.actions.setRoom(new ConferenceRoomInfo(room));
  };

  refreshParticipants = async () => {
    const participants = await this.conference.getParticipants();
    this.setParticipants(participants);
  };

  setParticipants = (participants: Participant[]) => {
    this.props.actions.setParticipants(createParticipantInfoList(participants));
  };

  onPrepare = () => {
    console.log('Waiting');
    this.props.actions.setStreamState('Waiting');
  };

  onStop = () => {
    console.log('Stopped');
    this.props.actions.setStreamState('Stopped');
  };

  onStart = (stream) => {
    console.log('Started stream: ', stream);
    this.props.actions.setStreamState('Started');
    this.props.actions.setMyStream(stream);
    this.refreshParticipants();
  };

  onConnect = () => {
    console.log('Connected');
    this.props.actions.setStreamState('Connected');
  };

  onDisconnect = () => {
    console.log('Disconnected');
    this.props.actions.setStreamState('Disconnected');
  };

  onFeedsReceived = (feeds) => {
    console.log('FeedsReceived feeds: ', feeds);
    this.setState({ feeds: [...feeds] });
    this.refreshParticipants();
  };

  onEvent = (key: string, msg: any) => {
    switch (key) {
      case 'leaving':
        if (msg['reason'] === 'kicked') {
          this.toggleBan();
        }
        break;
      case 'destroyed':
        this.toggleEnd();
        break;
    }
  };

  componentDidMount() {
    if (isClient) {
      if (!isWebRTCSupported()) {
        alert('your browser does not support webrtc');
      }

      const roomId = new URLSearchParams(window.location.search).get('id');

      if (!roomId) {
        Toast.warn("room's id are reqired");
        navigate('/demo');
        return;
      }

      this.initFlipFlop().then(() => {
        this.setState({ roomId });
        this.initConference();
      });
    }
  }

  componentWillUnmount() {
    this.conference?.stop();
  }

  public render() {
    const { streamState, myStream, participants } = this.props;
    const { feeds } = this.state;

    const style = {
      gridTemplateRows: `repeat(${participants.length > 2 ? '2' : '1'}, 1fr)`,
      gridTemplateColumns: `repeat(${
        participants.length === 2 ? 2 : Math.ceil(participants.length / 2)
      }, 1fr)`,
    };

    console.log('this.props.room', this.props.room);

    return (
      <div className="conference-viewer-container">
        <header className="conference-header">
          <Title color="white" size="large" className="montserrat-font">
            {this.props.room?.title || Messages.NO_TITLE}
          </Title>
          <ConferenceNavList />
        </header>
        <section className="conference-content flex-center" style={style}>
          {streamState === 'Waiting' && <WaitingConference />}
          {['Started', 'Connected', 'Disconnected'].includes(streamState) && (
            <>
              {myStream && (
                <article>
                  <LocalRoomVideo />
                </article>
              )}
              {participants.slice(1).map((_, i) => (
                <RemoteRoomVideo key={i} feed={feeds[i]} />
              ))}
            </>
          )}
        </section>
        <Modal
          className="demo-modal"
          visible={this.state.visibleModal === ModalStateCode.End}
          onOk={() => {
            navigate('/demo');
          }}
          disabled
          render={<p className="landing-description">회의가 종료되었습니다.</p>}
          title={'회의 종료'}
        />
        <Modal
          className="demo-modal"
          visible={this.state.visibleModal === ModalStateCode.Ban}
          onOk={() => {
            navigate('/demo');
          }}
          disabled
          render={<p className="landing-description">회의에서 강퇴되었습니다.</p>}
          title={'강제 퇴장'}
        />
        <Modal
          title="Password"
          visible={this.state.visibleModal === ModalStateCode.Enter}
          setVisible={this.toggleEnter}
          render={<EnterConferenceForm toggle={this.toggleEnter} roomId={this.state.roomId} />}
        />
      </div>
    );
  }
}

export default connect(
  ({ conference }: StoreState) => ({
    myStream: conference.myStream,
    room: conference.room,
    streamState: conference.streamState,
    participants: conference.participants,
  }),
  (dispatch) => ({
    actions: bindActionCreators(conferenceActions, dispatch),
  })
)(ConferenceViewerContainer);
