import * as React from 'react';

interface Props {
  className?: string;
}

export default function VideoLike({ className }: Props): JSX.Element {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path id="a1222" d="M0 0h9.286v14.857H0z" />
        <path id="c33333" d="M0 0h15.786v20.429H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h26v26H0z" />
        <g transform="translate(15.786 7.5)">
          <mask id="UserGroup" fill="#fff">
            <use xlinkHref="#a1222" />
          </mask>
          <path
            d="M9.286 13.029c-.005-3.128-1.77-5.764-4.19-6.607a3.418 3.418 0 0 0 1.784-3A3.43 3.43 0 0 0 3.44 0C1.54 0 0 1.532 0 3.423a3.42 3.42 0 0 0 1.783 2.999 5.497 5.497 0 0 0-1.676.951c1.276 1.836 2.018 4.163 2.021 6.67v.148c0 .218-.048.42-.119.612.46.034.936.054 1.43.054 3.23 0 5.847-.77 5.847-1.723 0-.035-.011-.07-.018-.105h.018z"
            fill="#FFF"
            mask="url(#UserGroup)"
          />
        </g>
        <g transform="translate(.929 3.786)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c33333" />
          </mask>
          <path
            d="M15.786 17.914c-.007-4.3-2.391-7.924-5.656-9.084a4.714 4.714 0 0 0 2.407-4.124c0-2.6-2.079-4.706-4.644-4.706-2.566 0-4.645 2.106-4.645 4.706 0 1.776.973 3.323 2.406 4.124C2.392 9.99.007 13.614 0 17.914h.023c-.007.05-.023.096-.023.145 0 1.31 3.534 2.37 7.89 2.37 4.362 0 7.896-1.06 7.896-2.37 0-.05-.014-.096-.024-.145h.024z"
            fill="#FFF"
            mask="url(#d)"
          />
        </g>
      </g>
    </svg>
  );
}
