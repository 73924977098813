import * as React from 'react';
import { useState, useCallback } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import { Mute, UnMute, UserGroup, VideoOff, VideoOn } from '../../components/images/svg';
import Modal from '../../components/modal/Modal';
import { StoreState } from '../../modules';
import ParticipantsModalContent from './ParticipantsModalContent';
import { Config } from '../../utils';
import { isClient } from '../../utils/browser';

type ConferenceNavItem = {
  img: JSX.Element
  title: string
  onClick(): void
}

interface NavItemProps {
  navItem: ConferenceNavItem
}

function NavItem ({navItem}: NavItemProps) {
  return (
    <li className="nav-item flex-center">
      <button type="button" style={{height: "46px"}} onClick={navItem.onClick}>
        <div className="flex-center">{navItem.img}</div>
        <span style={{marginTop: "4px"}}>{navItem.title}</span>
      </button>
    </li>
  )
}

enum ModalStateCode {
  Participants = 1,
  Close = 2,
}

export default function ConferenceNavList () {
  const { conference, participants, room } = useSelector((state: StoreState) => ({conference: state.conference.conference, participants: state.conference.participants, streamState: state.conference.streamState, room: state.conference.room}));
  const [muted, setMuted] = useState(conference?.mute());
  const [isCamera, setIsCamera] = useState(isClient && typeof JSON.parse(localStorage.getItem(Config.DEMO_IS_CAM_KEY)) === "boolean" ? JSON.parse(localStorage.getItem(Config.DEMO_IS_CAM_KEY)) : false);
  const [visibleModal, setVisibleModal] = useState(0);

  const toggleModalGenerator = useCallback((modalCode: ModalStateCode) => (): void => {
    if (visibleModal === modalCode) {
      setVisibleModal(0)
    } else {
      setVisibleModal(modalCode)
    }
  }, [visibleModal, setVisibleModal])

  const toggleParticipants = toggleModalGenerator(ModalStateCode.Participants)
  const toggleClose = toggleModalGenerator(ModalStateCode.Close)

  const deleteRoom = useCallback(() => {
    conference?.deleteRoom(room?.id)
    navigate("/demo");
  }, [conference, room?.id])

  const navBarOps: ConferenceNavItem[] = [
    {
      img: muted ? <Mute /> : <UnMute />,
      title: "음소거",
      onClick: () => {
        setMuted(conference.mute() ? conference.mute(false) : conference.mute(true))
      }
    },
    {
      img: isCamera ? <VideoOn /> : <VideoOff />,
      title: "비디오 중지",
      onClick: () => {
        setIsCamera(conference.camera() ? conference.camera(false) : conference.camera(true))
      }
    },
    {
      img: <UserGroup />,
      title: `참가자 ${participants.length}`,
      onClick: () => { toggleParticipants() }
    },
  ]

  return (
    <>
      <nav className="navbar-group">
        <ul className="navbar-nav">
          {navBarOps.map((item, i) => (
            <NavItem key={i} navItem={item} />
          ))}
        </ul>
      </nav>
      <button onClick={toggleClose} className="close-button flex-center">종료<div className="close-icon" /></button>
      <Modal title={`Member(${participants.length || 0})`} visible={visibleModal === ModalStateCode.Participants} setVisible={toggleParticipants} render={<ParticipantsModalContent participants={participants}/>} />
      <Modal className="conference-close-modal-container" visible={visibleModal === ModalStateCode.Close} setVisible={toggleClose} noHeader render={
        <div className="button-group">
          {room?.isHost && <Button size="large" color="red" onClick={deleteRoom} style={{height: "45px", width: "280px"}} >방 삭제 및 나가기</Button>}
          <Button size="large" color="black" onClick={() => navigate("/demo")} style={{height: "45px", width: "280px", marginTop: "10px"}} >방 나가기</Button>
          <Button size="large" color="normal" onClick={toggleClose} style={{height: "45px", width: "280px", marginTop: "10px"}}>취소</Button>
        </div>
      }/>
    </>
  )
}