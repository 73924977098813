import * as React from 'react';
import { useEffect } from 'react';
import { StoreState } from '../../modules';
import { useSelector } from 'react-redux';
import { VideoMoreIcon } from '../../components/images/svg';
import { Config } from '../../utils';
import { isClient } from '../../utils/browser';

export default function LocalRoomVideo() {
  const { conference, myStream, streamState, room } = useSelector((state: StoreState) => ({conference: state.conference.conference, myStream: state.conference.myStream, streamState: state.conference.streamState, room: state.conference.room}))

  useEffect(() => {
    const update = () => {
      const localVideoDom = document.getElementById('myvideo') as HTMLVideoElement;
      
      if (myStream && localVideoDom)
        console.log(myStream)
        if (streamState ==="Connected" || localVideoDom?.srcObject === null) {
          conference.attachMediaStream(localVideoDom, myStream);
        }
    };

    setTimeout(() => {
      update();
    }, 500);
  }, [myStream, streamState]);

  const NoVideo = () => (
    <div
      className="no-video-container flex-center"
    >
      <img src={Config.DEFAULT_THUMBNAIL} alt="no_video_image" width="196" height="170" />
    </div>
  );

  console.log("streamState", streamState)
  return (
    <div
      className={'videolocal'}
      style={{ top: 0, right: 0 }}
    >
      {streamState === 'Disconnected' ? (
        <NoVideo />
      ) : streamState === 'Started' ? (
        <div className="blockingUI">
          <div className="blockingElement">
            <b>Publishing...</b>
          </div>
        </div>
      ) : streamState === 'Connected' ? (
        <>
          {room?.isHost && <button type="button" className="video-more-icon">
            <VideoMoreIcon className="orange-svg-icon" />
          </button>}
          <span className="video-username">{isClient ? localStorage.getItem(Config.DEMO_USERNAME_KEY) : ""}</span>
          <video
            id="myvideo"
            autoPlay
            playsInline
            muted
          />
        </>
      ) : null}
    </div>
  );
}
