import * as React from 'react';

interface Props {
  className?: string;
}

export default function UnMute({ className }: Props): JSX.Element {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h26v26H0z" />
        <path
          d="M16.5 22.6a.5.5 0 0 1 .5.5v.4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-.4a.5.5 0 0 1 .5-.5h7zM6.333 10v2.1c0 3.38 2.535 6.138 5.718 6.293l.242.006h1.418l.18-.003c3.116-.12 5.625-2.735 5.77-6l.006-.296-.001-2.1H21v2.1c0 4.016-2.929 7.315-6.666 7.669V22.6h-2.667l-.001-2.831C7.929 19.415 5 16.116 5 12.1V10h1.333zM13 2a5 5 0 0 1 5 5v5a5 5 0 0 1-10 0V7a5 5 0 0 1 5-5z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}
