import * as React from 'react';

interface Props {
  className?: string;
}

export default function VideoMoreIcon({ className }: Props): JSX.Element {
  switch (className) {
    case 'orange-svg-icon':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g fill="none" fillRule="evenodd">
            <circle fillOpacity=".7" fill="#ffa300" cx="12" cy="12" r="12" />
            <g transform="translate(11 6)" fill="#FFF">
              <circle cx="1.25" cy="1.25" r="1.25" />
              <circle cx="1.25" cy="6.25" r="1.25" />
              <circle cx="1.25" cy="11.25" r="1.25" />
            </g>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g fill="none" fillRule="evenodd">
            <circle fillOpacity=".7" fill="#000" cx="12" cy="12" r="12" />
            <g transform="translate(11 6)" fill="#FFF">
              <circle cx="1.25" cy="1.25" r="1.25" />
              <circle cx="1.25" cy="6.25" r="1.25" />
              <circle cx="1.25" cy="11.25" r="1.25" />
            </g>
          </g>
        </svg>
      );
  }
}
