import * as React from 'react';

export default function WaitingConference() {
  return (
    <div className="waiting_container" id="details">
      <div className="waiting_group flex-center">
        <div className="waiting_video_spinner"></div>
        <p className="waiting_content">화상회의에 진입중입니다.</p>
        <p>잠시만 기다려주세요.</p>
      </div>
    </div>
  );
}
