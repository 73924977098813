import * as React from 'react';

interface Props {
  className?: string;
}

export default function OutIcon({ className }: Props): JSX.Element {
  switch (className) {
    case 'white-svg-icon':
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <filter colorInterpolationFilters="auto" id="ajjj">
              <feColorMatrix
                in="SourceGraphic"
                values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
              />
            </filter>
            <path id="bnb" d="M0 0h22.857v29H0z" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h40v40H0z" />
            <g filter="url(#ajjj)">
              <g transform="translate(5 6)">
                <mask id="cuiu" fill="#fff">
                  <use xlinkHref="#bnb" />
                </mask>
                <path
                  d="M22.857 29H1.47C.66 29 0 28.37 0 27.594V1.406C0 .63.659 0 1.47 0h21.387v2.813H2.94v23.374h19.917V29z"
                  fill="#000"
                  mask="url(#cuiu)"
                />
              </g>
              <path
                d="m36.577 18.638-7.5-8.06-2.044 2.198 5.03 5.407h-17.92v3.108h17.92l-5.03 5.406 2.045 2.198 7.499-8.06a1.637 1.637 0 0 0 0-2.197"
                fill="#000"
              />
            </g>
          </g>
        </svg>
      );
    default:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <filter colorInterpolationFilters="auto" id="a33211">
              <feColorMatrix
                in="SourceGraphic"
                values="0 0 0 0 0.600000 0 0 0 0 0.600000 0 0 0 0 0.564706 0 0 0 1.000000 0"
              />
            </filter>
            <path id="lasdf" d="M0 0h11v14H0z" />
          </defs>
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h16v16H0z" />
            <g filter="url(#a33211)">
              <g transform="translate(0 1)">
                <mask id="dwer" fill="#fff">
                  <use xlinkHref="#lasdf" />
                </mask>
                <path
                  d="M11 14H.707C.317 14 0 13.696 0 13.321V.679C0 .304.317 0 .707 0H11v1.358H1.415v11.284H11V14z"
                  fill="#000"
                  mask="url(#dwer)"
                />
              </g>
              <path
                d="M15.796 7.52 12.187 4l-.984.96 2.421 2.361H5V8.68h8.624l-2.42 2.361.983.96 3.61-3.52a.667.667 0 0 0 0-.96"
                fill="#000"
              />
            </g>
          </g>
        </svg>
      );
  }
}
