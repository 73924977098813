import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { actions as conferenceActions } from '../../modules/conference'
import { StoreState } from '../../modules';
import { useDispatch, useSelector } from 'react-redux';
import { OutIcon, VideoCloseIcon, VideoMoreIcon } from '../../components/images/svg';
import Config from '../../utils/Config'
import { createParticipantInfoList } from '../conferences';

interface Props {
  feed: any;
}

export default function RemoteRoomVideo({feed}: Props) {
  const { conference, streamState, room, selectedVideoIcon } = useSelector((state: StoreState) => ({conference: state.conference.conference, myStream: state.conference.myStream, streamState: state.conference.streamState, room: state.conference.room, selectedVideoIcon: state.conference.selectedVideoIcon }))
  const dispatch = useDispatch();

  useEffect(() => {
    let remoteVideoDom = document.getElementById(`remotevideo${feed?.rfindex}`) as HTMLVideoElement

    console.log("feed!", feed);
    if (feed?.stream) conference.attachMediaStream(remoteVideoDom, feed?.stream)
  }, [feed?.videoTracks, streamState]);

  const NoVideo = () => (
    <div className="no-video-container flex-center">
      <img src={Config.DEFAULT_THUMBNAIL} alt="no_video_image" width="196" height="170" />
    </div>
  )

  const banMember = useCallback((memberId) => async () => {
    try {
      await conference.banMember(memberId);
      const participants = await conference.getPaticipants()
      dispatch(conferenceActions.setParticipants(createParticipantInfoList(participants)))
      dispatch(conferenceActions.setSelectedVideoIcon(null))  
    } catch {
      console.log("error");
    }
  }, [])

  const onClickToolIcon = useCallback((rfindex: number | null) => () => {
    dispatch(conferenceActions.setSelectedVideoIcon(rfindex))
  }, [])

  // console.log(props.janusState, props.video, typeof props.video, props.pluginType)
  console.log("room.createdBy.user_id", room)
  
  return (
    <article>
      <div
        className={"videoremote"}
        id={`videoremote${feed?.rfindex}`}
      >
        {feed?.videoTracks && feed?.videoTracks?.length !== 0 ?
        <>
          {room?.isHost ?
          feed?.rfdisplay !== room.createdBy.user_id ?
          (feed?.rfindex === selectedVideoIcon ?
            <button type="button" className="video-close-icon" onClick={onClickToolIcon(null)}>
              <VideoCloseIcon />
            </button> :
            <button type="button" className="video-more-icon" onClick={onClickToolIcon(feed?.rfindex)}>
              <VideoMoreIcon />
            </button>) : (
            <button type="button" className="video-more-icon" >
              <VideoMoreIcon className="orange-svg-icon" />
            </button>) : <></>}
          <span className="video-username">{feed?.rfdisplay}</span>
          {selectedVideoIcon === feed?.rfindex &&
          <div className="video-tool-group flex-center">
            <div className="button-group flex-center">
              <button type="button" onClick={banMember(feed?.rfid)}><OutIcon className="white-svg-icon" /><span>내보내기</span></button>
            </div>
          </div>}
          <video
            id={`remotevideo${feed?.rfindex}`}
            autoPlay
            playsInline
          ></video>
        </> :
        <NoVideo />}
      </div>
    </article>
  );
}
