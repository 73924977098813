import * as React from 'react';

interface Props {
  className?: string;
}

export default function VideoOff({ className }: Props): JSX.Element {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h26v26H0z" />
        <path
          d="M25.125 6.573c.54.304.875.877.875 1.499v9.856c0 .622-.335 1.195-.875 1.498-.54.304-1.203.291-1.73-.034l-4.814-2.959v3.477c0 .8-.659 1.447-1.472 1.447H7.971L21.649 7.68l1.745-1.072a1.707 1.707 0 0 1 1.73-.035zm-8.016-1.93c.525 0 .985.27 1.246.675L2.319 21.353l-.06-.002a1.457 1.457 0 0 1-1.33-1.441V6.09c0-.8.659-1.447 1.471-1.447h14.71z"
          fill="#FFF"
        />
        <path fill="#FF591F" d="M25.668 1.54 3.04 24.167l-.707-.707L24.961.833z" />
      </g>
    </svg>
  );
}
